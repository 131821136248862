<template>
  <a-row :gutter="10" class="mb-4 py-2">
    <a-col :span="labelCol" class="label mr-4 opacity-8 text-md" :class="{'text-right':labelRight}" >
      {{label}}：
    </a-col>
    <a-col :span="wrapCol">
      <slot></slot>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    label: String,
    labelCol: {
      type: [String,Number],
      default: 4
    },
    wrapCol: {
      type: [String,Number],
      default: 14
    },
    labelRight: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>
.info-item
  line-height 32px
</style>