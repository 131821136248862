var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-row",
    { staticClass: "mb-4 py-2", attrs: { gutter: 10 } },
    [
      _c(
        "a-col",
        {
          staticClass: "label mr-4 opacity-8 text-md",
          class: { "text-right": _vm.labelRight },
          attrs: { span: _vm.labelCol },
        },
        [_vm._v("\n    " + _vm._s(_vm.label) + "：\n  ")]
      ),
      _c("a-col", { attrs: { span: _vm.wrapCol } }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }